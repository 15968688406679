<template>
  <PageHeader
    :title="tituloPaginaUppercase"
    :level1="{ title: 'Estadística', route: '/menu' }"
    :level2="{ title: tituloPagina, route: '/' }"
    goBack
  />

  <div class="flex flex-wrap mb-4">
    <a-form class="md:flex w-full gap-4" ref="formRefFilter" layout="horizontal" :model="formState">
      <a-form-item
        v-if="isPacienteNinio"
        label="Filtrar por edad (tienen)"
        name="search"
        class="mb-0 w-full lg:w-1/3"
      >
        <a-select class="w-40" v-model:value="formState.dia_inicio">
          <a-select-option value="0">TODOS</a-select-option>
          <a-select-option value="30">1 mes</a-select-option>
          <a-select-option value="60">2 meses</a-select-option>
          <a-select-option value="91">3 meses</a-select-option>
          <a-select-option value="121">4 meses</a-select-option>
          <a-select-option value="152">5 meses</a-select-option>
          <a-select-option value="182">6 meses</a-select-option>
          <a-select-option value="212">7 meses</a-select-option>
          <a-select-option value="243">8 meses</a-select-option>
          <a-select-option value="273">9 meses</a-select-option>
          <a-select-option value="304">10 meses</a-select-option>
          <a-select-option value="334">11 meses</a-select-option>
          <a-select-option value="365">1 año</a-select-option>
          <a-select-option value="730">2 años</a-select-option>
          <a-select-option value="1095">3 años</a-select-option>
          <a-select-option value="1460">4 años</a-select-option>
          <a-select-option value="1825">5 años</a-select-option>
        </a-select>
      </a-form-item>

      <a-form-item
        label="Fecha de proceso"
        name="fecha_corte"
        class="mb-4 w-full lg:w-2/3"
        v-if="isPacienteNinio"
      >
        <a-date-picker v-model:value="formState.fecha_corte" format="DD/MM/YYYY" />
        <a-button @click="applyFilters">Aplicar</a-button>
      </a-form-item>

      <div v-if="!isPacienteNinio">
        <a-form-item label="Fecha Inicio" name="fecha_inicio" class="mb-4 w-full lg:w-3/3">
          <a-date-picker v-model:value="formState.fecha_inicio" format="DD/MM/YYYY" />
        </a-form-item>
        <a-form-item label="Fecha Fin" name="fecha_fin" class="mb-4 w-full lg:w-3/3">
          <a-date-picker v-model:value="formState.fecha_fin" format="DD/MM/YYYY" />
          <a-button @click="applyFilters">Aplicar</a-button>
        </a-form-item>
      </div>

      <a-button v-print="printObjTodo" v-if="isPacienteNinio">
        <template #icon><FilePdfFilled class="text-red-500" /></template>
        Descargar todo
      </a-button>
    </a-form>
  </div>

  <div class="flex flex-col gap-3 lg:flex-row">
    <div class="w-full lg:w-1/3">
      <a-card size="small">
        <template #title>
          <div class="text-center flex justify-between items-center px-3 py-1">
            <p class="font-semibold">DATOS POR PROVINCIA</p>
            <div class="flex gap-3">
              <a-tooltip>
                <template #title>Traer de nuevo todos los datos</template>
                <a-button shape="circle" @click="resetAllFetch">
                  <template #icon><ReloadOutlined /></template>
                </a-button>
              </a-tooltip>

              <a-tooltip>
                <template #title>Descargar datos por provincia</template>
                <a-button
                  shape="circle"
                  v-print="printObj"
                  class="text-red-500 hover:text-red-300"
                  @click="filterToPrint('provincia')"
                >
                  <template #icon><FilePdfFilled /></template>
                </a-button>
              </a-tooltip>

              <a-tooltip>
                <template #title>Descargar datos por provincia</template>
                <a-button
                  shape="circle"
                  @click="handleProvincia"
                  class="text-green-500 hover:text-green-300"
                >
                  <template #icon><FileExcelFilled /></template>
                </a-button>
              </a-tooltip>
            </div>
          </div>
        </template>

        <div class="overflow-x-auto w-full">
          <a-table
            :columns="columns1"
            :data-source="data1"
            size="small"
            :pagination="true"
            :custom-row="customRow"
            rowKey="nombre"
            :loading="isLoading1"
            :rowClassName="
              (record, index) =>
                index % 2 === 0 ? 'cursor-pointer bg-white' : 'cursor-pointer bg-gray-100'
            "
          >
            <template #rangepages="{ record }">
              <span> {{ formateaDataProvincia.indexOf(record) + 1 }} </span>
            </template>

            <template #total_formateado="{ text: total_formateado }">
              <p class="text-right">{{ total_formateado }}</p>
            </template>

            <template #porcentaje="{ record }">
              <p class="text-right font-bold">{{ record.porcentaje }} %</p>
            </template>
          </a-table>
        </div>
        <p class="text-gray-400 my-4 text-right">TOTAL: {{ TOTAL_PROVINCIA }}</p>
      </a-card>
    </div>

    <div class="w-full lg:w-1/3">
      <a-card size="small">
        <template #title>
          <div class="text-center flex justify-between items-center px-3 py-1">
            <div class="text-left">
              <p class="font-semibold">DATOS POR DISTRITO</p>
              <p v-if="dataToPrint.provinciaFiltrado" class="text-sm text-gray-500">
                <FilterOutlined />provincia {{ dataToPrint.provinciaFiltrado }}
              </p>
            </div>

            <div class="flex gap-3">
              <a-tooltip>
                <template #title>Descargar datos por distrito</template>
                <a-button
                  shape="circle"
                  v-print="printObj"
                  class="text-red-500 hover:text-red-300"
                  @click="filterToPrint('distrito')"
                >
                  <template #icon><FilePdfFilled /></template>
                </a-button>
              </a-tooltip>

              <a-tooltip>
                <template #title>Descargar datos por distrito</template>
                <a-button
                  shape="circle"
                  class="text-green-500 hover:text-green-300"
                  @click="handleDistrito"
                >
                  <template #icon><FileExcelFilled /></template>
                </a-button>
              </a-tooltip>
            </div>
          </div>
        </template>

        <div class="overflow-x-auto w-full">
          <a-table
            :columns="columns2"
            :data-source="data2"
            size="small"
            :custom-row="customRow2"
            :pagination="true"
            rowKey="nombre"
            :loading="isLoading2"
            :rowClassName="
              (record, index) =>
                index % 2 === 0 ? 'cursor-pointer bg-white' : 'cursor-pointer bg-gray-100'
            "
          >
            <template #rangepages="{ record }">
              <span> {{ formateaDataDistrito.indexOf(record) + 1 }} </span>
            </template>

            <template #total_formateado="{ text: total_formateado }">
              <p class="text-right">{{ total_formateado }}</p>
            </template>

            <template #porcentaje="{ record }">
              <p class="text-right font-bold">{{ record.porcentaje }} %</p>
            </template>
          </a-table>
        </div>
        <p class="text-gray-400 my-4 text-right">TOTAL: {{ TOTAL_DISTRITO }}</p>
      </a-card>
    </div>

    <div class="w-full lg:w-1/3">
      <a-card size="small">
        <template #title>
          <div class="text-center flex justify-between items-center px-3 py-1">
            <div class="text-left">
              <p class="font-semibold">DATOS POR ESTABLECIMIENTO</p>
              <p v-if="dataToPrint.distritoFiltrado" class="text-sm text-gray-500">
                <FilterOutlined />distrito {{ dataToPrint.distritoFiltrado }}
              </p>
            </div>
            <div class="flex gap-3">
              <a-tooltip>
                <template #title>Descargar datos por establecimiento</template>
                <a-button
                  shape="circle"
                  v-print="printObj"
                  class="text-red-500 hover:text-red-300"
                  @click="filterToPrint('establecimiento')"
                >
                  <template #icon><FilePdfFilled /></template>
                </a-button>
              </a-tooltip>

              <a-tooltip>
                <template #title>Descargar datos por establecimiento</template>
                <a-button
                  shape="circle"
                  class="text-green-500 hover:text-green-300"
                  @click="handleEstablecimiento"
                >
                  <template #icon><FileExcelFilled /></template>
                </a-button>
              </a-tooltip>
            </div>
          </div>
        </template>
        <div class="overflow-x-auto w-full">
          <a-table
            :columns="columns4"
            :data-source="data4"
            size="small"
            :pagination="true"
            rowKey="nombre"
            :loading="isLoading3"
            :rowClassName="(record, index) => (index % 2 === 0 ? 'bg-white' : 'bg-gray-100')"
          >
            <template #rangepages="{ record }">
              <span> {{ formateaEstablecimiento.indexOf(record) + 1 }} </span>
            </template>

            <template #porcentaje="{ record }">
              <p class="text-center font-bold">{{ record.porcentaje }} %</p>
            </template>
          </a-table>
        </div>
        <p class="text-gray-400 my-4 text-right">TOTAL: {{ TOTAL_ESTABLECIMIENTO }}</p>
      </a-card>
    </div>
  </div>

  <FooterFuente fuente="PADRÓN NOMINAL" :fecha="fechaIngreso" />

  <PrintCantidadPorcentual
    :fechaIngreso="fechaIngreso"
    :tituloReporte="tituloPaginaUppercase"
    :dataToPrint="dataToPrint"
    :arraySelectPrint="arraySelectPrint"
    :tituloDeFiltro="tituloDeFiltro"
  />
</template>

<script>
import { ref, watch, reactive, computed, onMounted } from "vue";
import { useStore } from "vuex";
import PacienteReporteApi from "@/api/pacientereporte";
import { numberWithCommas, calculaPorcentaje } from "@/utils";
import { columns1, columns2, columns3, columns4 } from "./utilsCantidadPorcentual";
import PrintCantidadPorcentual from "@/print/PrintCantidadPorcentual.vue";
import ApiDescargas from "@/api/descargas";
import PageHeader from "@/components/PageHeader.vue";
import FooterFuente from "@/components/FooterFuente.vue";
import UsuariosApi from "@/api/user";
import moment from "moment";
import { useRoute } from "vue-router";
import {
  FileExcelFilled,
  ReloadOutlined,
  FilePdfFilled,
  FilterOutlined
} from "@ant-design/icons-vue";

export default {
  components: {
    PageHeader,
    PrintCantidadPorcentual,
    FooterFuente,
    // icons
    FileExcelFilled,
    ReloadOutlined,
    FilePdfFilled,
    FilterOutlined
  },
  setup() {
    const store = useStore();
    const isLoading1 = ref(false);
    const isLoading2 = ref(false);
    const isLoading3 = ref(false);
    const data1 = ref([]);
    const data2 = ref([]);
    const data3 = ref([]);
    const data4 = ref([]);
    const fechaIngreso = ref("");
    const activeKey = ref("1");
    const { establecimiento } = store.state.user.usuario ? store.state.user.usuario : "";
    const arraySelectPrint = ref([]);
    const tituloDeFiltro = ref("");
    const dataToPrint = reactive({
      provincia: [],
      distrito: [],
      centroPoblado: [],
      establecimiento: [],
      provinciaFiltrado: "",
      distritoFiltrado: ""
    });

    const TOTAL_PROVINCIA = ref(0.0);
    const TOTAL_DISTRITO = ref(0.0);
    const TOTAL_ESTABLECIMIENTO = ref(0.0);

    const route = useRoute();
    const tipopaciente = route.params?.tipopaciente;

    const isPacienteNinio = tipopaciente === "ninios";
    const idtipopaciente = isPacienteNinio ? "1" : "2";

    const tituloPagina = computed(() =>
      isPacienteNinio ? "Población nominal de niños < de 5 años" : "Población nominal de gestantes"
    );

    const tituloPaginaUppercase = computed(() =>
      isPacienteNinio
        ? "POBLACIÓN DE PADRÓN NOMINAL DE NIÑOS Y NIÑAS MENORES DE 5 AÑOS"
        : "POBLACIÓN DE PADRÓN NOMINAL DE GESTANTES"
    );

    const printObj = reactive({
      id: "printCantidadPorcentual",
      popTitle: "SIVINUR | " + tituloPaginaUppercase.value
    });

    const printObjTodo = reactive({
      id: "printCantidadPorcentualTodo",
      popTitle: "SIVINUR | Reporte de todos"
    });

    // dia_inicio: "1825", // 5 años

    const formState = reactive({
      dia_inicio: "1825",
      dia_final: 1825 + 364,
      fecha_corte: moment(),
      fecha_inicio: moment().day(-180),
      fecha_fin: moment()
    });

    watch(formState, () => {
      formState.dia_final =
        Number(formState.dia_inicio) + (Number(formState.dia_inicio) > 364 ? 364 : 29);
    });

    const fetchProvincia = (fecha_corte, dia_inicio, dia_final, fecha_inicio, fecha_fin) => {
      isLoading1.value = true;

      if (idtipopaciente == 1) {
        PacienteReporteApi.getAllProvincia(
          idtipopaciente,
          fecha_corte,
          dia_inicio,
          dia_final,
          fecha_inicio,
          fecha_fin
        )
          .then(async (response) => {
            var sum = 0;
            response.data.forEach((element) => {
              sum += parseInt(element.total);
            });
            TOTAL_PROVINCIA.value = sum;

            // console.log("getAllProvincia", response);
            const totalDenominador = response.data.reduce((a, b) => a + Number(b.total), 0);
            const conPorcentajes = response.data.map((p) => ({
              ...p,
              total_formateado: numberWithCommas(p.total),
              porcentaje: calculaPorcentaje(p.total, totalDenominador)
            }));
            console.log(conPorcentajes);
            data1.value = conPorcentajes;
            dataToPrint.provincia = conPorcentajes;
          })
          .catch((err) => console.log(err))
          .finally(() => {
            isLoading1.value = false;
          });
      } else {
        PacienteReporteApi.getAllProvinciaGestante(idtipopaciente, fecha_inicio, fecha_fin)
          .then(async (response) => {
            var sum = 0;
            response.data.forEach((element) => {
              sum += parseInt(element.total);
            });
            TOTAL_PROVINCIA.value = sum;

            // console.log("getAllProvincia", response);
            const totalDenominador = response.data.reduce((a, b) => a + Number(b.total), 0);
            const conPorcentajes = response.data.map((p) => ({
              ...p,
              total_formateado: numberWithCommas(p.total),
              porcentaje: calculaPorcentaje(p.total, totalDenominador)
            }));
            console.log(conPorcentajes);
            data1.value = conPorcentajes;
            dataToPrint.provincia = conPorcentajes;
          })
          .catch((err) => console.log(err))
          .finally(() => {
            isLoading1.value = false;
          });
      }
    };

    const fetchDistrito = (fecha_corte, dia_inicio, dia_final, fecha_inicio, fecha_fin) => {
      const provincia = store.getters["pacientereporte/provincia"];
      isLoading2.value = true;

      if (idtipopaciente == 1) {
        PacienteReporteApi.getAllDistrito(
          provincia,
          idtipopaciente,
          fecha_corte,
          dia_inicio,
          dia_final
        )
          .then(async (response) => {
            var sum = 0;
            response.data.forEach((element) => {
              sum += parseInt(element.total);
            });
            TOTAL_DISTRITO.value = sum;

            // console.log("getAllDistrito", response);

            const totalDenominador = response.data.reduce((a, b) => a + Number(b.total), 0);

            const conPorcentajes = response.data.map((p) => ({
              ...p,
              total_formateado: numberWithCommas(p.total),
              porcentaje: calculaPorcentaje(p.total, totalDenominador)
            }));
            data2.value = conPorcentajes;
            dataToPrint.distrito = conPorcentajes;
          })
          .catch((err) => console.log(err))
          .finally(() => (isLoading2.value = false));
      } else {
        PacienteReporteApi.getAllDistritoGestante(
          provincia,
          idtipopaciente,
          fecha_inicio,
          fecha_fin
        )
          .then(async (response) => {
            var sum = 0;
            response.data.forEach((element) => {
              sum += parseInt(element.total);
            });
            TOTAL_DISTRITO.value = sum;
            // console.log("getAllDistrito", response);

            const totalDenominador = response.data.reduce((a, b) => a + Number(b.total), 0);

            const conPorcentajes = response.data.map((p) => ({
              ...p,
              total_formateado: numberWithCommas(p.total),
              porcentaje: calculaPorcentaje(p.total, totalDenominador)
            }));
            data2.value = conPorcentajes;
            dataToPrint.distrito = conPorcentajes;
          })
          .catch((err) => console.log(err))
          .finally(() => (isLoading2.value = false));
      }
    };

    const fetchCentroPoblado = (fecha_corte, dia_inicio, dia_final, fecha_inicio, fecha_fin) => {
      const provincia = store.getters["pacientereporte/provincia"];
      const distrito = store.getters["pacientereporte/distrito"];
      isLoading3.value = true;

      if (idtipopaciente == 1) {
        PacienteReporteApi.getAllCentroPoblado(
          provincia,
          distrito,
          idtipopaciente,
          fecha_corte,
          dia_inicio,
          dia_final
        )
          .then(async (response) => {
            // console.log("getAllCentroPoblado", response);

            const totalDenominador = response.data.reduce((a, b) => a + Number(b.total), 0);

            const conPorcentajes = response.data.map((p) => ({
              ...p,
              total_formateado: numberWithCommas(p.total),
              porcentaje: calculaPorcentaje(p.total, totalDenominador)
            }));
            data3.value = conPorcentajes;
            dataToPrint.centroPoblado = conPorcentajes;
          })
          .catch((err) => console.log(err))
          .finally(() => (isLoading3.value = false));
      } else {
        PacienteReporteApi.getAllCentroPobladoGestante(
          provincia,
          distrito,
          idtipopaciente,
          fecha_inicio,
          fecha_fin
        )
          .then(async (response) => {
            // console.log("getAllCentroPoblado", response);

            const totalDenominador = response.data.reduce((a, b) => a + Number(b.total), 0);

            const conPorcentajes = response.data.map((p) => ({
              ...p,
              total_formateado: numberWithCommas(p.total),
              porcentaje: calculaPorcentaje(p.total, totalDenominador)
            }));
            data3.value = conPorcentajes;
            dataToPrint.centroPoblado = conPorcentajes;
          })
          .catch((err) => console.log(err))
          .finally(() => (isLoading3.value = false));
      }
    };

    const fetchEstablecimiento = (fecha_corte, dia_inicio, dia_final, fecha_inicio, fecha_fin) => {
      const provincia = store.getters["pacientereporte/provincia"];
      const distrito = store.getters["pacientereporte/distrito"];
      isLoading3.value = true;

      if (idtipopaciente == 1) {
        PacienteReporteApi.getAllEstablecimiento(
          provincia,
          distrito,
          idtipopaciente,
          fecha_corte,
          dia_inicio,
          dia_final
        )
          .then(async (response) => {
            var sum = 0;
            response.data.forEach((element) => {
              sum += parseInt(element.total);
            });
            TOTAL_ESTABLECIMIENTO.value = sum;

            // console.log("getAllEstablecimiento", response);
            const totalDenominador = response.data.reduce((a, b) => a + Number(b.total), 0);

            const conPorcentajes = response.data.map((p) => ({
              ...p,
              total_formateado: numberWithCommas(p.total),
              porcentaje: calculaPorcentaje(p.total, totalDenominador)
            }));
            data4.value = conPorcentajes;
            dataToPrint.establecimiento = conPorcentajes;
          })
          .catch((err) => console.log(err))
          .finally(() => (isLoading3.value = false));
      } else {
        PacienteReporteApi.getAllEstablecimientoGestante(
          provincia,
          distrito,
          idtipopaciente,
          fecha_inicio,
          fecha_fin
        )
          .then(async (response) => {
            var sum = 0;
            response.data.forEach((element) => {
              sum += parseInt(element.total);
            });
            TOTAL_ESTABLECIMIENTO.value = sum;
            // console.log("getAllEstablecimiento", response);
            const totalDenominador = response.data.reduce((a, b) => a + Number(b.total), 0);

            const conPorcentajes = response.data.map((p) => ({
              ...p,
              total_formateado: numberWithCommas(p.total),
              porcentaje: calculaPorcentaje(p.total, totalDenominador)
            }));
            data4.value = conPorcentajes;
            dataToPrint.establecimiento = conPorcentajes;
          })
          .catch((err) => console.log(err))
          .finally(() => (isLoading3.value = false));
      }
    };

    const fetchFechaImportacion = () => {
      UsuariosApi.getFechaImportacion("padron_nominal_new")
        .then((res) => {
          fechaIngreso.value =
            moment(res.data[0]?.fechaingreso).format("DD/MM/YYYY") || "No registrado";
        })
        .catch((err) => {
          console.log(err);
        });
    };

    watch(store.state.pacientereporte, () => {
      console.log("paciente reporte", store.state.pacientereporte);
      dataToPrint.provinciaFiltrado = store.state.pacientereporte.provincia;
      dataToPrint.distritoFiltrado = store.state.pacientereporte.distrito;
    });

    onMounted(() => {
      UsuariosApi.userLogAdd(
        "Estadística",
        "Cantidad Porcentual " + idtipopaciente,
        establecimiento
      );

      store.dispatch("pacientereporte/setprovincia", "");
      store.dispatch("pacientereporte/setdistrito", "");

      const fecha_corte = formState.fecha_corte.format("YYYY-MM-DD");
      const dia_inicio = formState.dia_inicio;
      const dia_final = formState.dia_final;

      const fecha_inicio = formState.fecha_inicio.format("YYYY-MM-DD");
      const fecha_fin = formState.fecha_fin.format("YYYY-MM-DD");

      fetchProvincia(fecha_corte, dia_inicio, dia_final, fecha_inicio, fecha_fin);
      fetchDistrito(fecha_corte, dia_inicio, dia_final, fecha_inicio, fecha_fin);
      fetchCentroPoblado(fecha_corte, dia_inicio, dia_final, fecha_inicio, fecha_fin);
      fetchEstablecimiento(fecha_corte, dia_inicio, dia_final, fecha_inicio, fecha_fin);
      fetchFechaImportacion();
    });

    const customRow = (record) => {
      return {
        onClick: () => {
          store.dispatch("pacientereporte/setprovincia", record.nombre);

          const fecha_inicio = formState.fecha_inicio.format("YYYY-MM-DD");
          const fecha_fin = formState.fecha_fin.format("YYYY-MM-DD");

          const fecha_corte = formState.fecha_corte.format("YYYY-MM-DD");
          const dia_inicio = formState.dia_inicio;
          const dia_final = formState.dia_final;

          fetchDistrito(fecha_corte, dia_inicio, dia_final, fecha_inicio, fecha_fin);
        }
      };
    };

    const customRow2 = (record) => {
      return {
        onClick: () => {
          store.dispatch("pacientereporte/setdistrito", record.nombre);

          const fecha_inicio = formState.fecha_inicio.format("YYYY-MM-DD");
          const fecha_fin = formState.fecha_fin.format("YYYY-MM-DD");

          const fecha_corte = formState.fecha_corte.format("YYYY-MM-DD");
          const dia_inicio = formState.dia_inicio;
          const dia_final = formState.dia_final;

          fetchCentroPoblado(fecha_corte, dia_inicio, dia_final, fecha_inicio, fecha_fin);
          fetchEstablecimiento(fecha_corte, dia_inicio, dia_final, fecha_inicio, fecha_fin);
        }
      };
    };

    const resetAllFetch = () => {
      store.dispatch("pacientereporte/setprovincia", "");
      store.dispatch("pacientereporte/setdistrito", "");

      const fecha_inicio = formState.fecha_inicio.format("YYYY-MM-DD");
      const fecha_fin = formState.fecha_fin.format("YYYY-MM-DD");

      const fecha_corte = formState.fecha_corte.format("YYYY-MM-DD");
      const dia_inicio = formState.dia_inicio;
      const dia_final = formState.dia_final;

      fetchProvincia(fecha_corte, dia_inicio, dia_final, fecha_inicio, fecha_fin);
      fetchDistrito(fecha_corte, dia_inicio, dia_final, fecha_inicio, fecha_fin);
      fetchCentroPoblado(fecha_corte, dia_inicio, dia_final, fecha_inicio, fecha_fin);
      fetchEstablecimiento(fecha_corte, dia_inicio, dia_final, fecha_inicio, fecha_fin);
    };

    const handleProvincia = () => {
      if (idtipopaciente == 1) {
        const fecha_corte = formState.fecha_corte.format("YYYY-MM-DD");
        const dia_inicio = formState.dia_inicio;
        const dia_final =
          Number(formState.dia_inicio) + (Number(formState.dia_inicio) > 364 ? 364 : 29);

        ApiDescargas.getReportePacienteProvincia(idtipopaciente, fecha_corte, dia_inicio, dia_final)
          .then(async () => {})
          .catch((err) => console.log(err));
      } else {
        const fecha_inicio = formState.fecha_inicio.format("YYYY-MM-DD");
        const fecha_fin = formState.fecha_fin.format("YYYY-MM-DD");
        ApiDescargas.getReportePacienteProvinciaGestante(idtipopaciente, fecha_inicio, fecha_fin)
          .then(async () => {})
          .catch((err) => console.log(err));
      }
    };

    const handleDistrito = () => {
      if (idtipopaciente == 1) {
        const fecha_corte = formState.fecha_corte.format("YYYY-MM-DD");
        const dia_inicio = formState.dia_inicio;
        const dia_final =
          Number(formState.dia_inicio) + (Number(formState.dia_inicio) > 364 ? 364 : 29);

        const provincia = store.getters["pacientereporte/provincia"];

        ApiDescargas.getReportePacienteDistrito(
          provincia,
          idtipopaciente,
          fecha_corte,
          dia_inicio,
          dia_final
        )
          .then(async () => {})
          .catch((err) => console.log(err));
      } else {
        const fecha_inicio = formState.fecha_inicio.format("YYYY-MM-DD");
        const fecha_fin = formState.fecha_fin.format("YYYY-MM-DD");

        const provincia = store.getters["pacientereporte/provincia"];
        ApiDescargas.getReportePacienteDistritoGestante(
          provincia,
          idtipopaciente,
          fecha_inicio,
          fecha_fin
        )
          .then(async () => {})
          .catch((err) => console.log(err));
      }
    };

    const handleCentroPoblado = () => {
      if (idtipopaciente == 1) {
        const fecha_corte = formState.fecha_corte.format("YYYY-MM-DD");
        const dia_inicio = formState.dia_inicio;
        const dia_final =
          Number(formState.dia_inicio) + (Number(formState.dia_inicio) > 364 ? 364 : 29);

        const provincia = store.getters["pacientereporte/provincia"];
        const distrito = store.getters["pacientereporte/distrito"];

        ApiDescargas.getReportePacienteCentroPoblado(
          provincia,
          distrito,
          idtipopaciente,
          fecha_corte,
          dia_inicio,
          dia_final
        )
          .then(async () => {})
          .catch((err) => console.log(err));
      } else {
        const fecha_inicio = formState.fecha_inicio.format("YYYY-MM-DD");
        const fecha_fin = formState.fecha_fin.format("YYYY-MM-DD");

        const provincia = store.getters["pacientereporte/provincia"];
        const distrito = store.getters["pacientereporte/distrito"];

        ApiDescargas.getReportePacienteCentroPobladoGestante(
          provincia,
          distrito,
          idtipopaciente,
          fecha_inicio,
          fecha_fin
        )
          .then(async () => {})
          .catch((err) => console.log(err));
      }
    };

    const handleEstablecimiento = () => {
      if (idtipopaciente == 1) {
        const fecha_corte = formState.fecha_corte.format("YYYY-MM-DD");
        const dia_inicio = formState.dia_inicio;
        const dia_final =
          Number(formState.dia_inicio) + (Number(formState.dia_inicio) > 364 ? 364 : 29);

        const provincia = store.getters["pacientereporte/provincia"];
        const distrito = store.getters["pacientereporte/distrito"];

        ApiDescargas.getReporteEstablecimientoo(
          provincia,
          distrito,
          idtipopaciente,
          fecha_corte,
          dia_inicio,
          dia_final
        )
          .then(async () => {})
          .catch((err) => console.log(err));
      } else {
        const fecha_inicio = formState.fecha_inicio.format("YYYY-MM-DD");
        const fecha_fin = formState.fecha_fin.format("YYYY-MM-DD");

        const provincia = store.getters["pacientereporte/provincia"];
        const distrito = store.getters["pacientereporte/distrito"];

        ApiDescargas.getReporteEstablecimientooGestante(
          provincia,
          distrito,
          idtipopaciente,
          fecha_inicio,
          fecha_fin
        )
          .then(async () => {})
          .catch((err) => console.log(err));
      }
    };

    const filterToPrint = (areaGeografica) => {
      tituloDeFiltro.value = areaGeografica;
      const areaDesordenada = dataToPrint[areaGeografica];
      arraySelectPrint.value = areaDesordenada.sort((a, b) => {
        return Number(b.porcentaje) - Number(a.porcentaje);
      });
    };

    const applyFilters = () => {
      const fecha_inicio = formState.fecha_inicio.format("YYYY-MM-DD");
      const fecha_fin = formState.fecha_fin.format("YYYY-MM-DD");

      const fecha_corte = formState.fecha_corte.format("YYYY-MM-DD");
      const dia_inicio = formState.dia_inicio;
      const dia_final =
        Number(formState.dia_inicio) + (Number(formState.dia_inicio) > 364 ? 364 : 29);

      fetchProvincia(fecha_corte, dia_inicio, dia_final, fecha_inicio, fecha_fin);
      fetchDistrito(fecha_corte, dia_inicio, dia_final, fecha_inicio, fecha_fin);
      fetchCentroPoblado(fecha_corte, dia_inicio, dia_final, fecha_inicio, fecha_fin);
      fetchEstablecimiento(fecha_corte, dia_inicio, dia_final, fecha_inicio, fecha_fin);
    };

    return {
      tituloPaginaUppercase,
      data1,
      data2,
      data3,
      data4,
      handleProvincia,
      handleDistrito,
      handleCentroPoblado,
      handleEstablecimiento,
      isPacienteNinio,
      customRow,
      customRow2,
      resetAllFetch,
      columns1,
      columns2,
      columns3,
      columns4,
      isLoading1,
      isLoading2,
      isLoading3,
      activeKey,
      printObj,
      printObjTodo,
      filterToPrint,
      dataToPrint,
      arraySelectPrint,
      tituloDeFiltro,
      tituloPagina,
      fechaIngreso,
      formState,
      applyFilters,
      TOTAL_PROVINCIA,
      TOTAL_DISTRITO,
      TOTAL_ESTABLECIMIENTO
    };
  }
};
</script>
